export enum Metric {
    Temp24h = '8ab4f495-0797-4a5a-80c8-17ab63feb9f6',
    TempDay = 'f85655fa-cc3d-4ccb-af6e-6cb17bdd2975',
    TempNight = '55c6dffc-712b-4ee0-970c-b3dcfb449068',
    RadSumOut24h = 'cd297cd6-0f62-4c64-af91-53abda0dc3fd',
    CumRadSumOut = 'c10dd255-bc48-4601-bc14-7f9c5cd1a63b',
    Co224h = '080c294d-3d90-4e1c-80f1-61819da671a0',
    Co2Day = 'd4be3443-807f-4603-915b-55c1b74a5bc0',
    RH24h = '53a3813c-e688-4d48-800d-5d8ffbae4c85',
    RadSum24h = '5af6d6d0-7a35-4c3e-8d93-d99e1467ea76',
    WeekRadSum = '00982e6e-5969-4b4d-a2b6-eda74ef77c4b',
    ECDrain = 'e588ebee-1089-4402-88e5-4de3627df07d',
    PHDrain = '2458599d-6652-47a1-b31b-5983dc2c5c0b',
    // RTRatioAvg24hr = 'fae6ca22-8f9e-4f0e-900f-0ffe91351eb9',
    WaterUptake = 'dca38a8b-0bbd-4004-9cb7-a1868723e019',
    Dose = '96aa507d-6c60-4c70-b166-67b48669b015',
    Drain = '42a34022-04bd-451f-a595-ecf13d22eada',
    RTRPar = '6c124fdf-d2f8-4e47-b33f-fb2fd1909bc8',
    RTRRadSum = 'f0d4fff6-1e74-41f9-b2a1-2536fed7c740',
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { GreenhouseStateContainer } from './greenhouses-state.model';
import {
    loadGreenhouses,
    loadGreenhousesIfNeeded,
    loadGreenhousesSuccess,
    setGreenhouses,
} from './greenhouses.actions';

@Injectable({
    providedIn: 'root',
})
export class GreenhousesEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<GreenhouseStateContainer> = inject(Store);

    /**
     * This effect checks if the greenhouses are already in the store.
     * If not: then it will fire an action to really load the greenhouses
     */
    public loadGreenhousesIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadGreenhousesIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                if (!state.greenhouse.greenhouses) {
                    // We don't have the greenhouses, so request it
                    return of(loadGreenhouses());
                } else {
                    // We have the greenhouses already present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will fire an action to save this result in the store.
     */
    public loadGreenhouses$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadGreenhousesSuccess),
            mergeMap((action) => {
                return of(setGreenhouses({ greenhouses: action.greenhouses }));
            }),
        );
    });
}

export interface AnalysisStateContainer {
    analysis: AnalysisState;
}

export interface AnalysisState {
    metricDefinitions: MetricDefinition[];
}

export interface MetricDefinition {
    id: string;
}

export const initialAnalysisState: AnalysisState = {
    metricDefinitions: undefined,
};

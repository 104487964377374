import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { AnalysisApiService } from './analysis.api.service';

export interface MetricDefinitionsResponse {
    metricDefinitions: MetricDefinition[];
}

export interface MetricDefinition {
    id: string;
}

@Injectable({
    providedIn: 'root',
})
export class MetricDefinitionsApiService extends AnalysisApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.metricDefinitionsService;
    }

    public getMetricDefinitions(): Observable<MetricDefinitionsResponse> {
        return this.get<MetricDefinitionsResponse>('', []);
    }
}

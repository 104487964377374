import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { KpiDeviations } from '@app/crops';
import { AppConfigurationService, dateToLocalDateString, dateToLocalWeek } from '@app/utilities';

import { CropMonitoringApiService } from './crop-monitoring.api.service';

@Injectable({
    providedIn: 'root',
})
export class KpiDeviationsApiService extends CropMonitoringApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.kpiDeviationsService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    private replaceCropId(cropId: string): string {
        return (this._baseUri = this._baseUri.replace(':cropId', cropId));
    }

    private resetBaseUri(): void {
        this._baseUri = this._appConfigurationService.configuration.uris.kpiDeviationsService;
    }

    public getDayKpiDeviations(day: Dayjs, cropId: string, kpiIds: string[]): Observable<KpiDeviations> {
        this.setRequest(cropId);
        const params = this.getParams(kpiIds);
        const date = dateToLocalDateString(day);

        return this.get<KpiDeviations>(`dates/${date}`, [], params);
    }

    public getWeekKpiDeviations(day: Dayjs, cropId: string, kpiIds: string[]): Observable<KpiDeviations> {
        this.setRequest(cropId);
        const params = this.getParams(kpiIds);
        const date = dateToLocalWeek(day);

        return this.get<KpiDeviations>(`weeks/${date}`, [], params);
    }

    private getParams(kpiIds: string[]): HttpParams {
        let params = new HttpParams();

        kpiIds.forEach((kpiId) => (params = params.append('kpiIds', kpiId)));
        return params;
    }

    private setRequest(cropId: string) {
        this.resetBaseUri();
        this.replaceCropId(cropId);
    }
}

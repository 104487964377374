@if (form) {
    <form [formGroup]="form">
        <div class="row-container">
            <div class="row-item">
                <priva-form-group [hasLabel]="true" labelFor="name">
                    <ng-container data-label>{{ 'APP.CROP.NAME' | translate }}</ng-container>
                    <input
                        priva-input
                        id="name"
                        name="name"
                        type="text"
                        alignment="Left"
                        data-test="name"
                        formControlName="name"
                        [placeholder]="'APP.CROP.DIALOG.BASIC_INFORMATION.NAME_PLACEHOLDER' | translate"
                    />
                </priva-form-group>
            </div>
            <!-- Empty row-item div to fill up space -->
            <div class="row-item"></div>
        </div>
        <div class="row-container">
            <div class="row-item">
                <priva-form-group [hasLabel]="true" labelFor="crop-type">
                    <ng-container data-label>{{ 'APP.CROP.TYPE' | translate }}</ng-container>
                    <select
                        priva-select
                        id="crop-type"
                        formControlName="cropType"
                        [ngClass]="{ 'gray-text': cropTypeControl.value === null }"
                    >
                        <option disabled hidden selected [value]="null">
                            {{ 'APP.CROP.DIALOG.BASIC_INFORMATION.CROP_TYPE_PLACEHOLDER' | translate }}
                        </option>
                        @for (cropTypeOption of cropTypeOptions; track cropTypeOption) {
                            <option [value]="cropTypeOption.value">
                                {{ cropTypeOption.label }}
                            </option>
                        }
                    </select>
                </priva-form-group>
            </div>
            <div class="row-item">
                <priva-form-group [hasLabel]="true" labelFor="crop-sub-type">
                    <ng-container data-label>{{ 'APP.CROP.SEGMENT' | translate }}</ng-container>
                    <select
                        priva-select
                        id="crop-sub-type"
                        formControlName="cropSegment"
                        [ngClass]="{ 'gray-text': cropSegmentControl.value === null }"
                    >
                        <option disabled hidden selected [value]="null">
                            {{ 'APP.CROP.DIALOG.BASIC_INFORMATION.CROP_SEGMENT_PLACEHOLDER' | translate }}
                        </option>
                        @for (cropSegmentOption of cropSegmentOptions; track cropSegmentOption) {
                            <option [value]="cropSegmentOption.value">
                                {{ cropSegmentOption.label }}
                            </option>
                        }
                    </select>
                </priva-form-group>
            </div>
        </div>
        <div class="row-container">
            <div class="row-item date-picker-container">
                <priva-form-group [hasLabel]="true" labelFor="start-date">
                    <ng-container data-label>{{ 'APP.CROP.START_DATE' | translate }}</ng-container>
                    <dp-date-picker
                        priva-datepicker
                        mode="day"
                        theme="onwhite"
                        id="start-date"
                        formControlName="startDate"
                        [config]="startDatePickerConfig"
                        [placeholder]="'APP.CROP.DIALOG.BASIC_INFORMATION.START_DATE_PLACEHOLDER' | translate"
                        [displayDate]="startDateControl.value"
                        autocomplete="off"
                        required
                    />
                </priva-form-group>
            </div>
            <div class="row-item date-picker-container">
                <priva-form-group [hasLabel]="true" labelFor="end-date">
                    <ng-container data-label>{{ 'APP.CROP.END_DATE' | translate }}</ng-container>
                    <dp-date-picker
                        priva-datepicker
                        mode="day"
                        id="end-date"
                        theme="onwhite"
                        formControlName="endDate"
                        [config]="endDatePickerConfig"
                        [displayDate]="endDateControl.value"
                        [minDate]="endDatePickerConfig.min"
                        [placeholder]="'APP.CROP.DIALOG.BASIC_INFORMATION.END_DATE_PLACEHOLDER' | translate"
                        autocomplete="off"
                        required
                    />
                </priva-form-group>
            </div>
        </div>
    </form>
}

/**
 * This class serves as a base for storing to session storage.
 */
export abstract class SessionStorage {
    protected abstract readonly SESSION_STORAGE_KEY: string;
    protected readonly EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 1 day in milliseconds

    protected constructor() {
        this.clearExpiredSessionStorage();
    }

    protected clearExpiredSessionStorage() {
        const storedData = sessionStorage.getItem(this.SESSION_STORAGE_KEY);
        if (storedData) {
            const { timestamp } = JSON.parse(storedData);
            if (Date.now() - timestamp > this.EXPIRATION_TIME) {
                sessionStorage.removeItem(this.SESSION_STORAGE_KEY);
            }
        }
    }

    protected setSessionStorage(data: any) {
        const timestamp = Date.now();
        sessionStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify({ ...data, timestamp }));
    }

    protected getSessionStorage() {
        const storedData = sessionStorage.getItem(this.SESSION_STORAGE_KEY);
        return storedData ? JSON.parse(storedData) : null;
    }
}

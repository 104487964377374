import { createFeatureSelector, createSelector } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { ExpandedCrop } from '../../models';
import { CropsState, CropStateContainer } from '../crops';
import { GreenhousesState, GreenhouseStateContainer } from '../greenhouses';
import { SitesState, SiteStateContainer } from '../sites';

const getCropFeatureState = createFeatureSelector<CropsState>(nameof<CropStateContainer>('crop'));
const getSiteFeatureState = createFeatureSelector<SitesState>(nameof<SiteStateContainer>('site'));
const getGreenhouseFeatureState = createFeatureSelector<GreenhousesState>(
    nameof<GreenhouseStateContainer>('greenhouse'),
);

export const selectExpandedCrops = createSelector(
    getCropFeatureState,
    getSiteFeatureState,
    getGreenhouseFeatureState,
    (cropState, siteState, greenhousesState) => {
        if (!cropState.crops || !siteState.sites || !greenhousesState.greenhouses) {
            return undefined;
        }

        return cropState.crops.map((crop) => {
            const expandedCrop: ExpandedCrop = {
                ...crop,
                site: siteState.sites.find((site) => site.id === crop.siteId),
                greenhouse: greenhousesState.greenhouses.find(
                    (greenhouse) => greenhouse.id === crop.greenhouseId,
                ),
            };

            return expandedCrop;
        });
    },
);

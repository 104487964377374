export enum CropSegment {
    // Unspecified
    Unspecified = 0,

    // Tomato
    Cherry = 100,
    Cocktail = 101,
    Plum = 102,
    Round = 103,
    Intermediate = 104,
    Beef = 105,
    TrussFine = 106,
    Trusslarge = 107,
    Loose = 108,
    SanMarzano = 109,
    TomatoSnack = 110,
    CoeurDeBoeuf = 111,
    Heirloom = 112,
    TomatoUnavailable = 113,
    CherryTruss = 114,
    Truss = 115,
}

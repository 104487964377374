import { createReducer, on } from '@ngrx/store';

import { initialSitesState, SitesState } from './sites-state.model';
import { setSites } from './sites.actions';

export const sitesReducer = createReducer(
    initialSitesState,
    on(setSites, (state, action): SitesState => {
        return {
            ...state,
            sites: action.sites,
        };
    }),
);

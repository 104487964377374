import { HttpClient, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, take, throwError } from 'rxjs';

import { PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { PrivaNotificationsService } from '@priva/components/notifications';

/**
 * This class serves as a base for all services that uses the http-client.
 */
export abstract class HttpService {
    private readonly _httpClient = inject(HttpClient);
    private readonly _userService = inject(PrivaAuthCurrentUserService);
    private readonly _notificationService = inject(PrivaNotificationsService);
    private readonly _apiVersion = 'api-version';
    private readonly _defaultHeaders = { 'Content-Type': 'application/json' };
    private _tenantId: string;
    /**
     * Override this property to specify the base uri of the service.
     */
    protected abstract get baseUri(): string;

    /**
     * Override this property to specify the version of the service.
     */
    protected abstract get version(): string;

    constructor() {
        this._userService.user.pipe(take(1)).subscribe((user) => (this._tenantId = user?.tenantId));
    }

    protected get<TOut>(
        uriPostFix?: string,
        allowedStatusCodes: HttpStatusCode[] = [],
        params?: HttpParams,
        extraHeaders?: { [name: string]: string },
    ): Observable<TOut> {
        let options: { headers: HttpHeaders; params: HttpParams };
        try {
            options = this.createHttpOptions(params, extraHeaders);
        } catch (error) {
            return throwError(() => error);
        }

        return this._httpClient
            .get<TOut>(this.createUri(uriPostFix), options)
            .pipe(this.allowedStatuses(this, allowedStatusCodes));
    }

    protected post<TIn, TOut = TIn>(
        uriPostFix: string,
        obj: TIn,
        allowedStatusCodes: HttpStatusCode[] = [],
        params?: HttpParams,
        extraHeaders?: { [name: string]: string },
    ): Observable<TOut> {
        let options: { headers: HttpHeaders; params: HttpParams };
        try {
            options = this.createHttpOptions(params, extraHeaders);
        } catch (error) {
            return throwError(() => error);
        }

        return this._httpClient
            .post<TOut>(this.createUri(uriPostFix), obj, options)
            .pipe(this.allowedStatuses(this, allowedStatusCodes));
    }

    protected put<TIn, TOut = TIn>(
        uriPostFix: string,
        obj: TIn,
        allowedStatusCodes: HttpStatusCode[] = [],
        params?: HttpParams,
        extraHeaders?: { [name: string]: string },
    ): Observable<TOut> {
        let options: { headers: HttpHeaders; params: HttpParams };
        try {
            options = this.createHttpOptions(params, extraHeaders);
        } catch (error) {
            return throwError(() => error);
        }

        return this._httpClient
            .put<TOut>(this.createUri(uriPostFix), obj, options)
            .pipe(this.allowedStatuses(this, allowedStatusCodes));
    }

    protected delete<TOut>(
        uriPostFix: string,
        allowedStatusCodes: HttpStatusCode[] = [],
        params?: HttpParams,
        extraHeaders?: { [name: string]: string },
    ): Observable<TOut> {
        let options: { headers: HttpHeaders; params: HttpParams };
        try {
            options = this.createHttpOptions(params, extraHeaders);
        } catch (error) {
            return throwError(() => error);
        }

        return this._httpClient
            .delete<TOut>(this.createUri(uriPostFix), options)
            .pipe(this.allowedStatuses(this, allowedStatusCodes));
    }

    private createUri(urlPostFix?: string): string {
        let uri = `${this.baseUri}${urlPostFix ? '/' + urlPostFix : ''}`;
        uri = uri.replace(':tenantId', this._tenantId);

        return uri;
    }

    private createHttpOptions(params?: HttpParams, extraHeaders?: { [name: string]: string }) {
        const headers = this.createHeaders(extraHeaders);

        return {
            headers,
            params,
        };
    }

    private createHeaders(extraHeaders?: { [name: string]: string }): HttpHeaders {
        const headers = extraHeaders || this._defaultHeaders;
        let httpHeaders = new HttpHeaders(headers);
        if (httpHeaders.has(this._apiVersion)) {
            throw new Error(
                `ExtraHeaders contains an ${this._apiVersion}, which should not be done! This is added automatically.`,
            );
        }

        // Always add the api-version to the headers
        httpHeaders = httpHeaders.append(this._apiVersion, this.version);

        return httpHeaders;
    }

    private allowedStatuses(httpService: HttpService, allowedStatusCodes: HttpStatusCode[] = []) {
        return <T>(source: Observable<T>): Observable<T> => {
            return source.pipe(
                catchError((error) => {
                    if (!allowedStatusCodes.includes(error.status)) {
                        const message = `Server for ${(httpService as any).constructor.name} returned error: ${error.statusText}`;
                        httpService.showErrorNotificationToaster(message);
                    }
                    return throwError(() => error);
                }),
            );
        };
    }

    private showErrorNotificationToaster(message: string) {
        this._notificationService.toaster.error({
            emphasized: 'Some data could not be retrieved.',
            message: message,
            emphasisBlock: false,
        });
    }
}

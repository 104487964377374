import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { Greenhouse } from '../models';
import { MasterdataApiService } from './masterdata-api.service';

@Injectable({
    providedIn: 'root',
})
export class GreenhousesApiService extends MasterdataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.greenhousesService;
    }

    public getGreenhouses(): Observable<Greenhouse[]> {
        return this.get<Greenhouse[]>();
    }
}

import { Component, HostListener, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
    template: '',
})
export abstract class CanDeactivateComponent {
    public confirmNavigation = signal(false);
    private confirm = new Subject<boolean>();

    abstract canDeactivate(unload?: boolean): Observable<boolean> | boolean;

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate(true)) {
            $event.returnValue = true;
        }
    }

    public discardChanges() {
        this.onDiscardChanges();
        this.confirm.next(true);
        this.confirmNavigation.set(false);
    }

    public continueEditing() {
        this.confirm.next(false);
        this.confirmNavigation.set(false);
    }

    protected abstract onDiscardChanges(): void;

    protected getConfirmObservable(): Observable<boolean> {
        return this.confirm.asObservable();
    }
}

import { CropSegment } from './crop-segment.enum';
import { CropType } from './crop-type.enum';

export const CropTypeSegments: Record<CropType, CropSegment[]> = {
    [CropType.Unspecified]: [CropSegment.Unspecified],
    [CropType.Tomato]: [
        CropSegment.Cherry,
        CropSegment.Cocktail,
        CropSegment.Plum,
        CropSegment.Round,
        CropSegment.Intermediate,
        CropSegment.Beef,
        CropSegment.TrussFine,
        CropSegment.Trusslarge,
        CropSegment.Loose,
        CropSegment.SanMarzano,
        CropSegment.TomatoSnack,
        CropSegment.CoeurDeBoeuf,
        CropSegment.Heirloom,
        CropSegment.TomatoUnavailable,
        CropSegment.CherryTruss,
        CropSegment.Truss,
    ],
};

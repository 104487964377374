import { createAction, props } from '@ngrx/store';

import { Crop, ExtendedCrop } from '../../models';

export const loadCropsIfNeeded = createAction('[Crops] Load crops if needed');

export const loadCrops = createAction('[Crops] Load crops');

export const loadCropsSuccess = createAction('[Crops] Load crops success', props<{ crops: Crop[] }>());

export const createCrop = createAction('[Crops] Create crop', props<{ crop: Crop; context?: unknown }>());

export const createCropSuccess = createAction(
    '[Crops] Create crop success',
    props<{ crop: Crop; context?: unknown }>(),
);

export const updateCrop = createAction('[Crops] Update crop', props<{ crop: Crop; context?: unknown }>());

export const updateCropSuccess = createAction(
    '[Crops] Update crop success',
    props<{ crop: Crop; context?: unknown }>(),
);

export const deleteCrop = createAction('[Crops] Delete crop', props<{ cropId: string }>());

export const deleteCropSuccess = createAction('[Crops] Delete crop success', props<{ crop: Crop }>());

export const setCrops = createAction('[Crops] Set crops', props<{ crops: ExtendedCrop[] }>());

export const setActive = createAction('[Crops] Set active', props<{ crop: ExtendedCrop }>());

import { createAction, props } from '@ngrx/store';

import { CropLocation, RowLocation } from '../../models';

export const loadRowLocationsIfNeeded = createAction(
    '[RowLocations] Load row-locations if needed',
    props<{ cropId: string }>(),
);

export const loadRowLocations = createAction(
    '[RowLocations] Load row-locations',
    props<{ cropId: string }>(),
);

export const loadRowLocationsSuccess = createAction(
    '[RowLocations] Load row-locations success',
    props<{ cropId: string; rowLocations: RowLocation[] }>(),
);

export const updateCropLocations = createAction(
    '[RowLocations] Update crop-locations',
    props<{ cropId: string; cropLocations: CropLocation[] }>(),
);

export const updateCropLocationsSuccess = createAction(
    '[RowLocations] Update crop-locations success',
    props<{ cropId: string; cropLocations: Record<string, boolean> }>(),
);

export const setRowLocations = createAction(
    '[RowLocations] Set row-locations',
    props<{ rowLocations: RowLocation[] }>(),
);

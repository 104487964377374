import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    determineActiveTab(url: string, tabList: any[]) {
        const urlSegments = url.split(/\/|#/);
        return tabList.find((x) => this.urlSegmentsContainTabId(urlSegments, x.id));
    }

    private urlSegmentsContainTabId(urlSegments: string[], id: string): boolean {
        return urlSegments.find((s) => s === id) !== undefined;
    }
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { RowLocationStateContainer } from './row-locations-state.model';
import {
    loadRowLocations,
    loadRowLocationsIfNeeded,
    loadRowLocationsSuccess,
    setRowLocations,
    updateCropLocationsSuccess,
} from './row-locations.actions';

@Injectable({
    providedIn: 'root',
})
export class RowLocationsEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<RowLocationStateContainer> = inject(Store);

    /**
     * This effect checks if the row-locations are already in the store.
     * If not: then it will fire an action to really load the row-locations
     */
    public loadRowLocationsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadRowLocationsIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([action, state]) => {
                const currentRowLocations = state.rowLocation?.rowLocations
                    ? state.rowLocation.rowLocations.filter(
                          (rowLocation) => rowLocation.Links.CropId === action.cropId,
                      )
                    : [];

                if (currentRowLocations.length === 0) {
                    // We don't have the row-locations, so request it
                    return of(loadRowLocations({ cropId: action.cropId }));
                } else {
                    // We have the row-locations already present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will fire an action to save this result in the store.
     */
    public loadRowLocations$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadRowLocationsSuccess),
            withLatestFrom(this._store),
            mergeMap(([action, state]) => {
                const filteredRowLocations = state.rowLocation?.rowLocations
                    ? state.rowLocation.rowLocations.filter(
                          (rowLocation) => rowLocation.Links.CropId !== action.cropId,
                      )
                    : [];

                return of(
                    setRowLocations({ rowLocations: filteredRowLocations.concat(action.rowLocations) }),
                );
            }),
        );
    });

    /**
     * This effect will fire an action to load the rowLocations after creation of the cropLocations
     */
    public updateRowLocations$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(updateCropLocationsSuccess),
            withLatestFrom(this._store),
            mergeMap(([action, state]) => {
                // The result of the update success action doesn't contain the actual rowLocations
                // but an indication if the update was successfull.
                // We ignore this for now and keep all the rowLocations for other crops
                // and load the rowLocations for this crop

                const otherCropRowLocations = state.rowLocation?.rowLocations
                    ? state.rowLocation.rowLocations.filter(
                          (rowLocation) => rowLocation.Links.CropId !== action.cropId,
                      )
                    : [];

                return of(
                    setRowLocations({ rowLocations: otherCropRowLocations }),
                    loadRowLocationsIfNeeded({ cropId: action.cropId }),
                );
            }),
        );
    });
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { KpiDefinitionResponse, KpiDefinitionsApiService } from '../kpi-definitions.api.service';
import { loadKpiDefinitions, loadKpiDefinitionsSuccess } from './monitoring.actions';

@Injectable({
    providedIn: 'root',
})
export class MonitoringApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _kpiDefinitionsService = inject(KpiDefinitionsApiService);

    /**
     * This effect will really request the kpiDefinitions from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadKpiDefinitions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadKpiDefinitions),
            mergeMap(() => {
                return this._kpiDefinitionsService.getKpiDefinitions().pipe(
                    map((response: KpiDefinitionResponse) =>
                        loadKpiDefinitionsSuccess({
                            kpiDefinitions: response.kpiDefinitions,
                        }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}

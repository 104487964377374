import { createReducer, on } from '@ngrx/store';

import { CropsState, initialCropsState } from './crops-state.model';
import { setActive, setCrops } from './crops.actions';

export const cropsReducer = createReducer(
    initialCropsState,
    on(setCrops, (state, action): CropsState => {
        return {
            ...state,
            crops: action.crops,
        };
    }),
    on(setActive, (state, action): CropsState => {
        return {
            ...state,
            activeCrop: action.crop,
        };
    }),
);

import { createAction, props } from '@ngrx/store';

import { MetricDefinition } from '../metric-definitions.api.service';

export const loadMetricDefinitionsIfNeeded = createAction('[Analysis] Load metric definitions if needed');

export const loadMetricDefinitions = createAction('[Analysis] Load metric definitions');

export const loadMetricDefinitionsSuccess = createAction(
    '[Analysis] Load metric definitions success',
    props<{ metricDefinitions: MetricDefinition[] }>(),
);

export const setMetricDefinitions = createAction(
    '[Analysis] Set metric definitions',
    props<{ metricDefinitions: MetricDefinition[] }>(),
);

import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppConfigurationService } from '@app/utilities';

@NgModule({
    imports: [],
    providers: [AppConfigurationService, HttpClient],
    declarations: [],
    exports: [],
})
export class MasterDataModule {}

import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { Row } from '../models';
import { MasterdataApiService } from './masterdata-api.service';

@Injectable({
    providedIn: 'root',
})
export class RowsApiService extends MasterdataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.rowsService;
    }

    public getRows(siteId?: string, greenhouseId?: string, compartmentId?: string): Observable<Row[]> {
        let params = new HttpParams();
        if (siteId) {
            params = params.append('siteId', siteId);
        }

        if (greenhouseId) {
            params = params.append('greenhouseId', greenhouseId);
        }

        if (compartmentId) {
            params = params.append('compartmentId', compartmentId);
        }

        return this.get<Row[]>(null, [], params);
    }
}

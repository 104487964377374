import { createAction, props } from '@ngrx/store';

import { KpiDefinition } from '../kpi-definitions.api.service';

export const loadKpiDefinitionsIfNeeded = createAction('[Monitoring] Load kpi definitions if needed');

export const loadKpiDefinitions = createAction('[Monitoring] Load kpi definitions');

export const loadKpiDefinitionsSuccess = createAction(
    '[Monitoring] Load kpi definitions success',
    props<{ kpiDefinitions: KpiDefinition[] }>(),
);

export const setKpiDefinitions = createAction(
    '[Monitoring] Set kpi definitions',
    props<{ kpiDefinitions: KpiDefinition[] }>(),
);

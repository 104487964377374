import { inject, Injectable } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { range } from 'lodash';
import { filter, map, Observable } from 'rxjs';

import { distinct } from '@priva/utilities/predicate';
import {
    PrivaVariableDataService,
    PrivaVariableRequestContainer,
    PrivaVariableResponse,
    ServiceType,
} from '@priva/variables';

import { roundToMetricDecimals } from '@app/metrics';

import { KPI_TARGETS_REQUEST_TYPE, KpiTargetsRequest } from './kpi-targets-request';
import { KpiTargetsApiService } from './kpi-targets.api.service';
import { KpiTarget } from './kpi-targets.model';

dayjs.extend(isBetween);

@Injectable({
    providedIn: 'root',
})
export class KpiTargetsService implements PrivaVariableDataService {
    private readonly kpiTargetsApiService = inject(KpiTargetsApiService);
    public readonly source = KPI_TARGETS_REQUEST_TYPE;
    public readonly type = ServiceType.Service;

    public getData(
        requestData: PrivaVariableRequestContainer<KpiTargetsRequest>[],
        _offsetInMinutes: number,
        start: Date,
        end: Date,
    ): Observable<PrivaVariableResponse> {
        const requests = requestData.map((requestContainer) => requestContainer.request);
        const kpiIdsForEachRequest = requests.flatMap(({ kpiId }) => kpiId).filter(distinct());
        if (kpiIdsForEachRequest.length !== 1) {
            return null;
        }

        const kpiIds = [kpiIdsForEachRequest[0]];
        const cropId = requestData[0].request.cropId;

        // We're only interested in the day here (startOf('day'), but in UTC format.
        const startDay = dayjs(start);
        const endDay = dayjs(end);
        const dateRange = this.getDateRange(startDay, endDay);

        return this.kpiTargetsApiService.getKpiTargets(cropId, kpiIds, startDay, endDay).pipe(
            filter(({ kpiTargets }) => !!kpiTargets?.length),
            map((response): PrivaVariableResponse => {
                let values = [];
                response.kpiTargets.forEach((kpiTarget: KpiTarget) => {
                    values = this.getTargetValues(dateRange, kpiTarget);
                });

                return this.createKpi(requestData, values);
            }),
        );
    }

    public getDateRange(start: Dayjs, end: Dayjs) {
        const startDay = dayjs.utc(start).startOf('day');
        const endDay = dayjs.utc(end).startOf('day');

        return range(startDay.diff(endDay.add(1, 'day'), 'day')).map((day) => startDay.subtract(day, 'day'));
    }

    public getTargetValues(dateRange: Dayjs[], response: KpiTarget) {
        return dateRange.map((date) => {
            // Find a target where the date falls within its periodStartDate and periodEndDate
            const targetVal = response.targets.find((target) =>
                date.isBetween(
                    dayjs.utc(target.periodStartDate),
                    dayjs.utc(target.periodEndDate),
                    null,
                    '[]',
                ),
            );

            return {
                target: {
                    x: date,
                    y: targetVal ? roundToMetricDecimals(targetVal.value, response.kpiId) : null,
                },
                yMin: {
                    x: date,
                    y: targetVal ? roundToMetricDecimals(targetVal.bandMinValue, response.kpiId) : null,
                },
                yMax: {
                    x: date,
                    y: targetVal ? roundToMetricDecimals(targetVal.bandMaxValue, response.kpiId) : null,
                },
            };
        });
    }

    public createKpi(requestData: PrivaVariableRequestContainer<KpiTargetsRequest>[], values) {
        const responseData = requestData.map((request) => {
            const key = request.variableId.split('_')[0];
            const valuesArray = values.map((value) => value[key]);
            return {
                props: request,
                values: valuesArray,
            };
        });

        return {
            type: this.source,
            data: [...responseData],
        };
    }
}

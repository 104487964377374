import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { CropsStoreModule } from '../crops';
import { GreenhousesStoreModule } from '../greenhouses';
import { SitesStoreModule } from '../sites';
import { CropsWithLocationsEffects } from './crops-with-locations.effects';

@NgModule({
    imports: [
        CropsStoreModule,
        SitesStoreModule,
        GreenhousesStoreModule,
        EffectsModule.forFeature([CropsWithLocationsEffects]),
    ],
    providers: [],
})
export class CropsWithLocationsStoreModule {}

import { createSelector } from '@ngrx/store';

import { RowLocationsState, RowLocationStateContainer } from './row-locations-state.model';

export const selectRowLocationState = (state: RowLocationStateContainer) => state.rowLocation;

export const selectRowLocations = createSelector(
    selectRowLocationState,
    (state: RowLocationsState) => state.rowLocations,
);

export const selectRowLocationsForCrop = (cropId: string) =>
    createSelector(selectRowLocations, (rowLocations) => {
        if (!rowLocations) {
            return [];
        }

        return rowLocations.filter((rowLocation) => rowLocation.Links.CropId === cropId);
    });

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { Greenhouse } from '../../models';
import { GreenhousesApiService } from '../../services';
import { loadGreenhouses, loadGreenhousesSuccess } from '../../state';

@Injectable({
    providedIn: 'root',
})
export class GreenhousesApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _greenhousesService = inject(GreenhousesApiService);

    /**
     * This effect will really request the greenhouses from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadGreenhouses$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadGreenhouses),
            mergeMap(() => {
                return this._greenhousesService.getGreenhouses().pipe(
                    map((greenhouses: Greenhouse[]) => loadGreenhousesSuccess({ greenhouses: greenhouses })),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { MasterDataModule } from '../../services/masterdata.module';
import { GreenhousesApiEffects } from './greenhouses-api.effects';
import { GreenhouseStateContainer } from './greenhouses-state.model';
import { GreenhousesEffects } from './greenhouses.effects';
import { greenhousesReducer } from './greenhouses.reducer';

@NgModule({
    imports: [
        MasterDataModule,
        StoreModule.forFeature(nameof<GreenhouseStateContainer>('greenhouse'), greenhousesReducer),
        EffectsModule.forFeature([GreenhousesEffects, GreenhousesApiEffects]),
    ],
    providers: [],
})
export class GreenhousesStoreModule {}

import { KpiDefinition } from '../kpi-definitions.api.service';

export interface MonitoringStateContainer {
    monitoring: MonitoringState;
}

export interface MonitoringState {
    kpiDefinitions: KpiDefinition[];
}

export const initialMonitoringState: MonitoringState = {
    kpiDefinitions: undefined,
};

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { DateFormat, DateKeyFormat } from '../date-constants';

dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs().format('L LT');

/*
 * Transform DateTime (dayjs) into dateKey (number)
 */
@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'dateToDateKey',
    standalone: true,
})
export class DateToDateKeyPipe implements PipeTransform {
    public transform(value: Dayjs): number {
        return value.year() * 10000 + (value.month() + 1) * 100 + value.date();
    }
}

/*
 * Transform DateKey (number) into DateTime (dayjs)
 */
@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'dateKeyToDate',
    standalone: true,
})
export class DateKeyToDatePipe implements PipeTransform {
    public transform(value: number): Dayjs {
        return stringToDate(value.toString(), DateKeyFormat);
    }
}

/*
 * Transform string into Date (dayjs)
 * Optionally takes a dateFormat to use for the conversion. If not provided it will take the default date format.
 */
@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'stringToDate',
    standalone: true,
})
export class StringToDatePipe implements PipeTransform {
    public transform(value: string, dateFormat?: string): Dayjs {
        return stringToDate(value, dateFormat);
    }
}

/*
 * Transform Date (dayjs) into string
 * Optionally takes a dateFormat to use for the conversion. If not provided it will take the default date format.
 */
@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'dateToString',
    standalone: true,
})
export class DateToStringPipe implements PipeTransform {
    public transform(value: Dayjs, dateFormat?: string): string {
        return value.format(dateFormat ? dateFormat : DateFormat);
    }
}

/*
 * Transform string into DateKey (number)
 */
@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'stringToDateKey',
    standalone: true,
})
export class StringToDateKeyPipe implements PipeTransform {
    public transform(value: string): number {
        const stringToDatePipe = new StringToDatePipe();
        const dateTime = stringToDatePipe.transform(value);

        const dateToDateKeyPipe = new DateToDateKeyPipe();
        return dateToDateKeyPipe.transform(dateTime);
    }
}

/*
 * Transform DateKey (number) into string
 */
@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'dateKeyToString',
    standalone: true,
})
export class DateKeyToStringPipe implements PipeTransform {
    public transform(value: number): string {
        if (value === null) {
            return null;
        }
        const dateKeyToDatePipe = new DateKeyToDatePipe();
        const dateTime = dateKeyToDatePipe.transform(value);

        const dateToStringPipe = new DateToStringPipe();
        return dateToStringPipe.transform(dateTime);
    }
}

function stringToDate(value: string, dateFormat?: string): Dayjs {
    // return dayjs(value, dateFormat ? dateFormat : DateFormatDayJS);
    const jsDate = dayjs(value, dateFormat || DateFormat).toDate();

    return dayjs(jsDate);
}

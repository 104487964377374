import { createReducer, on } from '@ngrx/store';

import { AnalysisState, initialAnalysisState } from './analysis-state.model';
import { setMetricDefinitions } from './analysis.actions';

export const analysisReducer = createReducer(
    initialAnalysisState,
    on(setMetricDefinitions, (state, action): AnalysisState => {
        return {
            ...state,
            metricDefinitions: action.metricDefinitions,
        };
    }),
);

@if (form && sites$) {
    <form [formGroup]="form">
        <div class="row-container">
            <div class="row-item">
                <priva-form-group [hasLabel]="true" labelFor="site">
                    <ng-container data-label>{{ 'APP.CROP.SITE' | translate }}</ng-container>
                    <select
                        priva-select
                        id="site"
                        formControlName="site"
                        [ngClass]="{ 'gray-text': siteControl.value === null }"
                    >
                        <option disabled hidden selected [value]="null">
                            {{ 'APP.CROP.DIALOG.LOCATION.SITE_PLACEHOLDER' | translate }}
                        </option>
                        @for (site of sites$ | async; track site) {
                            <option [value]="site.id">{{ site.name }}</option>
                        }
                    </select>
                </priva-form-group>
            </div>
            <div class="row-item">
                <priva-form-group [hasLabel]="true" labelFor="greenhouse">
                    <ng-container data-label>{{ 'APP.CROP.GREENHOUSE' | translate }}</ng-container>
                    <select
                        priva-select
                        id="greenhouse"
                        formControlName="greenhouse"
                        [ngClass]="{ 'gray-text': greenhouseControl.value === null }"
                    >
                        <option disabled hidden selected [value]="null">
                            {{ 'APP.CROP.DIALOG.LOCATION.GREENHOUSE_PLACEHOLDER' | translate }}
                        </option>
                        @for (greenhouse of greenhouses$ | async; track greenhouse) {
                            <option [value]="greenhouse.id">
                                {{ greenhouse.name }}
                            </option>
                        }
                    </select>
                </priva-form-group>
            </div>
        </div>
        <div class="row-container location">
            <priva-form-group [hasLabel]="true" labelFor="location">
                <ng-container data-label>{{ 'APP.CROP.LOCATION' | translate }}</ng-container>
                <div class="location-container">
                    <priva-form-input-group
                        [placeholder]="'APP.CROP.DIALOG.LOCATION.LOCATION_PLACEHOLDER' | translate"
                        prependType="Icon"
                        prependClass="far fa-filter"
                        (input)="filterLocations$.next($event.target.value)"
                        [isDisabled]="greenhouseControl.value === null"
                    />
                    <div class="location-tree">
                        <priva-tree
                            id="location"
                            class="scroll"
                            [items]="filteredLocationTreeItems$ | async"
                            [itemTemplate]="itemTemplate"
                            [config]="{ overflow: false }"
                        />
                        <ng-template #itemTemplate let-item>
                            <priva-tree-node-view [item]="item">
                                <div class="checkbox" [id]="item.id">
                                    <label class="form-control-label">
                                        <input
                                            type="checkbox"
                                            [id]="item.id"
                                            [name]="item.text"
                                            [value]="item.id"
                                            [checked]="item.isChecked"
                                            [indeterminate]="item.isIndeterminate"
                                            [attr.disabled]="item.isDisabled ? 'true' : null"
                                            (change)="onTreeItemToggle(item)"
                                        />
                                        <span
                                            class="input-box"
                                            [ngClass]="item.isIndeterminate ? 'input-box-indeterminate' : ''"
                                            [attr.disabled]="item.isDisabled ? 'true' : null"
                                        ></span>
                                        {{ item.text }}
                                    </label>
                                </div>
                            </priva-tree-node-view>
                        </ng-template>
                    </div>
                </div>
            </priva-form-group>
        </div>
    </form>
}

export class LocalStorage {
    private readonly storageKey: string;

    constructor(
        private storage: Storage,
        id = 'generic',
    ) {
        if (id.length > 64) {
            throw new Error('Filter id exceeds 64 character limit (used to conserve local storage space)');
        }
        this.storageKey = id;
    }

    public getItemOrDefault(defaultValue: any) {
        const storedValue = this.storage.getItem(this.storageKey);
        return storedValue === null ? defaultValue : storedValue;
    }

    public getItem() {
        return this.storage.getItem(this.storageKey);
    }

    public setItem(value: string) {
        this.storage.setItem(this.storageKey, value);
    }

    public getObjectOrDefault<T>(defaultValue: T) {
        const storedValue = this.storage.getItem(this.storageKey);
        if (!storedValue) return defaultValue;

        return JSON.parse(storedValue);
    }

    public getObject<T>(): T {
        return JSON.parse(this.storage.getItem(this.storageKey));
    }

    public setObject<T>(value: T) {
        this.storage.setItem(this.storageKey, JSON.stringify(value));
    }

    public removeItem() {
        this.storage.removeItem(this.storageKey);
    }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { KpiDefinitionsApiService } from '../kpi-definitions.api.service';
import { MonitoringApiEffects } from './monitoring-api.effects';
import { MonitoringStateContainer } from './monitoring-state.model';
import { MonitoringEffects } from './monitoring.effects';
import { monitoringReducer } from './monitoring.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(nameof<MonitoringStateContainer>('monitoring'), monitoringReducer),
        EffectsModule.forFeature([MonitoringEffects, MonitoringApiEffects]),
    ],
    providers: [KpiDefinitionsApiService],
})
export class MonitoringStoreModule {}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { SessionStorage } from '@app/utilities';

import { MonitoringStateContainer } from './monitoring-state.model';
import {
    loadKpiDefinitions,
    loadKpiDefinitionsIfNeeded,
    loadKpiDefinitionsSuccess,
    setKpiDefinitions,
} from './monitoring.actions';

@Injectable({
    providedIn: 'root',
})
export class MonitoringEffects extends SessionStorage {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<MonitoringStateContainer> = inject(Store);

    protected readonly SESSION_STORAGE_KEY = 'cropMonitoring';

    constructor() {
        super();
    }

    /**
     * This effect checks if the Kpi definitions are already in the store.
     * If not: then it will fire an action to really load the Kpi definitions.
     */
    public loadKpiDefinitionsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadKpiDefinitionsIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                const storedData = this.getSessionStorage();
                if (!state.monitoring.kpiDefinitions) {
                    // We don't have the Kpi definitions, check storage, then set or request it
                    return of(
                        storedData
                            ? setKpiDefinitions({ kpiDefinitions: storedData.kpiDefinitions })
                            : loadKpiDefinitions(),
                    );
                } else {
                    // We have the KpiDefinitions present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will convert the received kpiList into a simple array of strings
     * and will fire an action to save this result in the store.
     */
    public loadKpiDefinitionsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadKpiDefinitionsSuccess),
            mergeMap((action) => {
                const storedData = this.getSessionStorage();
                this.setSessionStorage({ ...storedData, kpiDefinitions: action.kpiDefinitions });

                return of(setKpiDefinitions({ kpiDefinitions: action.kpiDefinitions }));
            }),
        );
    });
}

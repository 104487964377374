import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { CropMonitoringApiService } from './crop-monitoring.api.service';

export interface KpiDefinitionResponse {
    kpiDefinitions: KpiDefinition[];
}

export interface KpiDefinition {
    id: string;
    defaultBandMinDelta?: number;
    defaultBandMaxDelta?: number;
}

@Injectable({
    providedIn: 'root',
})
export class KpiDefinitionsApiService extends CropMonitoringApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.kpiDefinitionsService;
    }

    public getKpiDefinitions(): Observable<KpiDefinitionResponse> {
        return this.get<KpiDefinitionResponse>('', []);
    }
}

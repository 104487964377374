import { createReducer, on } from '@ngrx/store';

import { initialRowLocationsState, RowLocationsState } from './row-locations-state.model';
import { setRowLocations } from './row-locations.actions';

export const rowLocationsReducer = createReducer(
    initialRowLocationsState,
    on(setRowLocations, (state, action): RowLocationsState => {
        return {
            ...state,
            rowLocations: action.rowLocations,
        };
    }),
);

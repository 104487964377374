import { createReducer, on } from '@ngrx/store';

import { GreenhousesState, initialGreenhousesState } from './greenhouses-state.model';
import { setGreenhouses } from './greenhouses.actions';

export const greenhousesReducer = createReducer(
    initialGreenhousesState,
    on(setGreenhouses, (state, action): GreenhousesState => {
        return {
            ...state,
            greenhouses: action.greenhouses,
        };
    }),
);

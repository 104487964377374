import { createSelector } from '@ngrx/store';

import { CropState } from '../../models';
import { CropsState, CropStateContainer } from './crops-state.model';

export const selectCropState = (state: CropStateContainer) => state.crop;

export const selectCrops = createSelector(selectCropState, (state: CropsState) => state.crops);

export const selectActiveCrop = createSelector(selectCropState, (state: CropsState) => state.activeCrop);

export const selectCurrentCrops = createSelector(selectCrops, (crops) => {
    if (!crops) {
        return undefined;
    }

    const currentCrops = crops.filter((crop) => crop.state === CropState.Current);
    currentCrops.sort((a, b) => a.name.localeCompare(b.name));
    return currentCrops;
});

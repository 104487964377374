import { MetricConfigs } from '@app/metrics';

import { KpiConfig } from './kpi-config-interface';

export const KpiConfigs: KpiConfig[] = MetricConfigs.filter((metricConfig) => !!metricConfig.kpiId).map(
    ({ id, testId, kpiId, isWeekly, unit, shortLabel, label, decimals, color }) => {
        return {
            id: kpiId,
            testId,
            label,
            shortLabel,
            color,
            decimals,
            isWeekly,
            unit,
            metricId: id,
        };
    },
);

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { MetricDefinitionsApiService } from '../metric-definitions.api.service';
import { AnalysisApiEffects } from './analysis-api.effects';
import { AnalysisStateContainer } from './analysis-state.model';
import { AnalysisEffects } from './analysis.effects';
import { analysisReducer } from './analysis.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(nameof<AnalysisStateContainer>('analysis'), analysisReducer),
        EffectsModule.forFeature([AnalysisEffects, AnalysisApiEffects]),
    ],
    providers: [MetricDefinitionsApiService],
})
export class AnalysisStoreModule {}

import { CropSegment } from './crop-segment.enum';

export const CropSegmentLabels: Record<CropSegment, string> = {
    // Unspecified
    [CropSegment.Unspecified]: 'APP.CROP_SEGMENT.UNSPECIFIED',

    // Tomato
    [CropSegment.Cherry]: 'APP.CROP_SEGMENT.CHERRY',
    [CropSegment.Cocktail]: 'APP.CROP_SEGMENT.COCKTAIL',
    [CropSegment.Plum]: 'APP.CROP_SEGMENT.PLUM',
    [CropSegment.Round]: 'APP.CROP_SEGMENT.ROUND',
    [CropSegment.Intermediate]: 'APP.CROP_SEGMENT.INTERMEDIATE',
    [CropSegment.Beef]: 'APP.CROP_SEGMENT.BEEF',
    [CropSegment.TrussFine]: 'APP.CROP_SEGMENT.TRUSSFINE',
    [CropSegment.Trusslarge]: 'APP.CROP_SEGMENT.TRUSSLARGER',
    [CropSegment.Loose]: 'APP.CROP_SEGMENT.LOOSE',
    [CropSegment.SanMarzano]: 'APP.CROP_SEGMENT.SANMARZANO',
    [CropSegment.TomatoSnack]: 'APP.CROP_SEGMENT.TOMATOSNACK',
    [CropSegment.CoeurDeBoeuf]: 'APP.CROP_SEGMENT.COUERDEBOEUF',
    [CropSegment.Heirloom]: 'APP.CROP_SEGMENT.HEIRLOOM',
    [CropSegment.TomatoUnavailable]: 'APP.CROP_SEGMENT.TOMATOUNAVAILABLE',
    [CropSegment.CherryTruss]: 'APP.CROP_SEGMENT.CHERRYTRUSS',
    [CropSegment.Truss]: 'APP.CROP_SEGMENT.TRUSS',
};

import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { Compartment } from '../models';
import { MasterdataApiService } from './masterdata-api.service';

@Injectable({
    providedIn: 'root',
})
export class CompartmentsApiService extends MasterdataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.compartmentsService;
    }

    public getCompartments(siteId?: string, greenhouseId?: string): Observable<Compartment[]> {
        let params = new HttpParams();
        if (siteId) {
            params = params.append('siteId', siteId);
        }

        if (greenhouseId) {
            params = params.append('greenhouseId', greenhouseId);
        }

        return this.get<Compartment[]>(null, [], params);
    }
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { MetricDefinitionsApiService, MetricDefinitionsResponse } from '../metric-definitions.api.service';
import { loadMetricDefinitions, loadMetricDefinitionsSuccess } from './analysis.actions';

@Injectable({
    providedIn: 'root',
})
export class AnalysisApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _metricDefinitionsService = inject(MetricDefinitionsApiService);

    /**
     * This effect will really request the metricDefinitions from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadMetricDefinitions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadMetricDefinitions),
            mergeMap(() => {
                return this._metricDefinitionsService.getMetricDefinitions().pipe(
                    map((response: MetricDefinitionsResponse) => {
                        return loadMetricDefinitionsSuccess({
                            metricDefinitions: response.metricDefinitions,
                        });
                    }),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { DateKeyToDatePipe } from '@app/utilities';

import { Crop, CropState, ExtendedCrop } from '../../models';
import { CropStateContainer } from './crops-state.model';
import {
    createCropSuccess,
    deleteCropSuccess,
    loadCrops,
    loadCropsIfNeeded,
    loadCropsSuccess,
    setCrops,
    updateCropSuccess,
} from './crops.actions';

@Injectable({
    providedIn: 'root',
})
export class CropsEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<CropStateContainer> = inject(Store);
    private readonly _dateKeyToDatePipe = inject(DateKeyToDatePipe);

    /**
     * This effect checks if the crops are already in the store.
     * If not: then it will fire an action to really load the crops.
     */
    public loadCropsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                if (!state.crop.crops) {
                    // We don't have the crops, so request it
                    return of(loadCrops());
                } else {
                    // We have the crops already present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will convert the received crops into ExtendedCrops
     * and will fire an action to save this result in the store.
     */
    public loadCropsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsSuccess),
            mergeMap((action) => {
                const extendedCrops = action.crops.map((crop) => {
                    return {
                        ...crop,
                        state: this.getCropState(crop),
                    } as ExtendedCrop;
                });

                return of(setCrops({ crops: extendedCrops }));
            }),
        );
    });

    /**
     * This effect will add the newly created crop to the existing crops
     * and fire an action to save this result in the store.
     */
    public createCropSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(createCropSuccess),
            withLatestFrom(this._store),
            mergeMap(([action, state]) => {
                const crops = [
                    ...state.crop.crops,
                    { ...action.crop, state: this.getCropState(action.crop) },
                ];

                return of(setCrops({ crops: crops }));
            }),
        );
    });

    /**
     * This effect will update the updated crop into the existing crops
     * and fire an action to save this result in the store.
     */
    public updateCropSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(updateCropSuccess),
            withLatestFrom(this._store),
            mergeMap(([action, state]) => {
                const crops = [
                    // Use all existing crops, except the one just updated...
                    ...state.crop.crops.filter((crop) => crop.id !== action.crop.id),
                    // ... and add the updated crop to it
                    { ...action.crop, state: this.getCropState(action.crop) },
                ];

                return of(setCrops({ crops: crops }));
            }),
        );
    });

    public deleteCropSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(deleteCropSuccess),
            withLatestFrom(this._store),
            mergeMap(([action, state]) => {
                // Use all existing crops, except the deleted one...
                const crops = state.crop.crops.filter((crop) => crop.id !== action.crop.id);
                return of(setCrops({ crops: crops }));
            }),
        );
    });

    private getCropState(crop: Crop): CropState {
        const now = dayjs();
        const startDate = this._dateKeyToDatePipe.transform(crop.startDateKey);
        const endDate = this._dateKeyToDatePipe.transform(crop.endDateKey);

        // If start date in future -> planned
        if (startDate > now) {
            return CropState.Upcoming;
        }

        // Start date is in the past
        if (!endDate || endDate > now) {
            return CropState.Current;
        }

        // If crop started but not yet ended -> active
        return CropState.Completed;
    }
}

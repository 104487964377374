import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import {
    createCrop,
    createCropSuccess,
    Crop,
    CropsApiService,
    deleteCrop,
    deleteCropSuccess,
    loadCrops,
    loadCropsSuccess,
    updateCrop,
    updateCropSuccess,
} from '../..';

@Injectable({
    providedIn: 'root',
})
export class CropsApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _cropsService = inject(CropsApiService);

    /**
     * This effect will really request the crops from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadCrops$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCrops),
            mergeMap(() => {
                return this._cropsService.getCrops().pipe(
                    map((crops: Crop[]) => loadCropsSuccess({ crops })),
                    catchError(() => EMPTY),
                );
            }),
        );
    });

    /**
     * This effect will create the crop in the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public createCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(createCrop),
            mergeMap((action) => {
                return this._cropsService.createCrop(action.crop).pipe(
                    map((crop: Crop) => createCropSuccess({ crop: crop, context: action.context })),
                    catchError(() => EMPTY),
                );
            }),
        );
    });

    /**
     * This effect will update the crop in the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public updateCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(updateCrop),
            mergeMap((action) => {
                return this._cropsService.updateCrop(action.crop).pipe(
                    map((crop: Crop) => updateCropSuccess({ crop: crop, context: action.context })),
                    catchError(() => EMPTY),
                );
            }),
        );
    });

    /**
     * This effect will delete the crop in the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public deleteCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(deleteCrop),
            mergeMap((action) => {
                return this._cropsService.deleteCrop(action.cropId).pipe(
                    map((crop: Crop) => deleteCropSuccess({ crop })),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}

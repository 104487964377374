import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { RowLocation } from '../../models';
import { CropsApiService } from '../../services';
import {
    loadRowLocations,
    loadRowLocationsSuccess,
    updateCropLocations,
    updateCropLocationsSuccess,
} from './row-locations.actions';

@Injectable({
    providedIn: 'root',
})
export class RowLocationsApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _cropsService = inject(CropsApiService);

    /**
     * This effect will really request the row-locations from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadRowLocations$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadRowLocations),
            mergeMap((action) => {
                return this._cropsService.getLocations(action.cropId).pipe(
                    map((rowLocations: RowLocation[]) =>
                        loadRowLocationsSuccess({ cropId: action.cropId, rowLocations }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        );
    });

    /**
     * This effect will update the crop-locations in the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public updateCropLocations$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(updateCropLocations),
            mergeMap((action) => {
                return this._cropsService.updateLocations(action.cropId, action.cropLocations).pipe(
                    map((cropLocations: Record<string, boolean>) =>
                        updateCropLocationsSuccess({ cropId: action.cropId, cropLocations }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}

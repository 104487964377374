import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { Site } from '../models';
import { MasterdataApiService } from './masterdata-api.service';

@Injectable({
    providedIn: 'root',
})
export class SitesApiService extends MasterdataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.sitesService;
    }

    public getSites(): Observable<Site[]> {
        return this.get<Site[]>();
    }
}

export enum CropType {
    Unspecified = 0,
    Tomato = 100,
    // Cucumber = 200,
    // Pepper = 300,
    // Strawberry = 400,
    // Lettuce = 500,
    // PottedPlants = 600,
    // Berries = 700,
    // Matricaria = 800,
    // YoungPlants = 900,
}

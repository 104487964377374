import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { CanDeactivateComponent } from './can-deactivate.component';

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
    canDeactivate(component: CanDeactivateComponent): Observable<boolean> | boolean {
        //if the component is destroyed, it can be deactivated
        if (!component) return true;
        return component.canDeactivate();
    }
}

import { createAction, props } from '@ngrx/store';

import { Site } from '../../models';

export const loadSitesIfNeeded = createAction('[Sites] Load sites if needed');

export const loadSites = createAction('[Sites] Load sites');

export const loadSitesSuccess = createAction('[Sites] Load sites success', props<{ sites: Site[] }>());

export const setSites = createAction('[Sites] Set sites', props<{ sites: Site[] }>());

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { CropsStoreModule } from '../crops';
import { GreenhousesStoreModule } from '../greenhouses';
import { SitesStoreModule } from '../sites';
import { ExpandedCropsEffects } from './expanded-crops.effects';

@NgModule({
    imports: [
        CropsStoreModule,
        SitesStoreModule,
        GreenhousesStoreModule,
        EffectsModule.forFeature([ExpandedCropsEffects]),
    ],
    providers: [],
})
export class ExpandedCropsStoreModule {}

<p>{{ 'APP.CROP.DIALOG.DELETE.WARNING' | translate }}</p>

<ul class="plain-text">
    <li>{{ 'APP.CROP.DIALOG.DELETE.CROP_INFORMATION' | translate }}</li>
    <li>{{ 'APP.CROP.DIALOG.DELETE.CONTROL_SETTINGS' | translate }}</li>
    <li>{{ 'APP.CROP.DIALOG.DELETE.PLANT_REGISTRATION' | translate }}</li>
</ul>

<priva-well>
    <priva-form-group [hasLabel]="true" labelFor="delete" [formGroup]="deleteForm">
        <ng-container data-label>{{ 'APP.CROP.DIALOG.DELETE.CONFIRM' | translate }}</ng-container>
        <input
            priva-input
            id="delete"
            data-test="confirm-delete-input"
            type="text"
            alignment="left"
            formControlName="deleteInput"
        />
    </priva-form-group>
</priva-well>

import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { DpDatePickerModule, IDatePickerConfig } from 'ng2-date-picker';
import { map, Subject, takeUntil } from 'rxjs';

import { PrivaDatepickerModule } from '@priva/components/datepicker';
import { PrivaFormGroupModule } from '@priva/components/form-group';
import { PrivaInputModule } from '@priva/components/input';
import { PrivaSelectModule, PrivaSelectOption } from '@priva/components/select';
import { PrivaLocalizationService } from '@priva/localization';
import {
    Crop,
    CropSegment,
    CropSegmentLabels,
    CropType,
    CropTypeLabels,
    CropTypeSegments,
} from '@priva/masterdata';

import { DatePickerService, DateService } from '@app/utilities';

interface formCrop {
    name: string;
    cropType: number;
    cropSegment: number;
    startDate: string;
    endDate: string;
}

@Component({
    selector: 'app-crop-basic-information',
    templateUrl: './crop-basic-information.component.html',
    styleUrls: ['./crop-basic-information.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        PrivaFormGroupModule,
        PrivaInputModule,
        PrivaSelectModule,
        NgClass,
        DpDatePickerModule,
        TranslateModule,
        PrivaDatepickerModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropBasicInformationComponent implements OnInit, OnDestroy {
    private readonly _localizationService = inject(PrivaLocalizationService);
    private readonly _datePickerService = inject(DatePickerService);
    private readonly _dateService = inject(DateService);

    private readonly _unsubscribe$: Subject<void> = new Subject<void>();

    @Input() public crop: Crop;

    @Output() public validChange: EventEmitter<{ isValid: boolean; crop: Crop }> = new EventEmitter();

    public form: FormGroup;

    public cropTypeOptions: PrivaSelectOption[] = [];
    public cropSegmentOptions: PrivaSelectOption[] = [];

    public startDatePickerConfig: IDatePickerConfig;
    public endDatePickerConfig: IDatePickerConfig;

    public get startDateControl(): AbstractControl {
        return this.form.controls.startDate;
    }

    public get endDateControl(): AbstractControl {
        return this.form.controls.endDate;
    }

    public get cropTypeControl(): AbstractControl {
        return this.form.controls.cropType;
    }

    public get cropSegmentControl(): AbstractControl {
        return this.form.controls.cropSegment;
    }

    public ngOnInit(): void {
        this.createForm();
        this.initializeOptions();

        this.generateDatePickers();

        this.adjustEndDatePickerConfig(this.form.controls.startDate.value);
    }

    public ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    private createForm(): void {
        const formValues = this.getFormValuesFromCrop();
        this.form = new FormGroup({
            name: new FormControl(formValues.name, {
                validators: [Validators.required],
                updateOn: 'change',
            }),
            cropType: new FormControl<number | null>(formValues.cropType, [Validators.required]),
            cropSegment: new FormControl<number | null>(formValues.cropSegment, [Validators.required]),
            startDate: new FormControl(formValues.startDate, [Validators.required]),
            endDate: new FormControl(formValues.endDate, [Validators.required]),
        });

        this.watchCropTypeChanges();
        this.watchStartDateChanges();
        this.watchValidityChanges();
    }

    private initializeOptions() {
        // Create cropTypeOptions only once based upon the enum record
        Object.entries(CropTypeLabels).forEach(([key, value]) => {
            this.cropTypeOptions.push({ value: key, label: this._localizationService.instant(value) });
        });

        // Create segmentOptions when cropType changes (see watchCropTypeChanges)
        // and thus now for the unspecified cropType
        this.createCropSegmentOptions(this.form.controls.cropType.value ?? CropType.Unspecified);
    }

    private generateDatePickers(): void {
        this.startDatePickerConfig = this._datePickerService.createConfig({
            allowMultiSelect: false,
            drops: 'up',
            opens: 'left',
        });

        this.endDatePickerConfig = this._datePickerService.createConfig({
            allowMultiSelect: false,
            drops: 'up',
            opens: 'right',
        });
    }

    private watchCropTypeChanges(): void {
        this.form.controls.cropType.valueChanges
            .pipe(
                takeUntil(this._unsubscribe$),
                map((value) => this.createCropSegmentOptions(value)),
            )
            .subscribe();
    }

    private watchStartDateChanges(): void {
        this.form.controls.startDate.valueChanges
            .pipe(
                takeUntil(this._unsubscribe$),
                map((value) => this.adjustEndDatePickerConfig(value)),
            )
            .subscribe();
    }

    private watchValidityChanges(): void {
        this.form.statusChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((status) => {
            const validChangeObject = { isValid: status === 'VALID', crop: this.getCropFromFormValues() };
            this.validChange.emit(validChangeObject);
        });
    }

    private createCropSegmentOptions(cropType: CropType) {
        // CropType is changed, so 'reset' the cropSegment value
        this.form?.controls.cropSegment.reset();

        // Get the cropSegments belonging to the selected cropType
        // and create the segment options out of it.
        const cropTypeString = cropType.toString();
        Object.entries(CropTypeSegments)
            .filter(([key]) => key === cropTypeString)
            .map(([_, values]) => {
                const segmentOptions: PrivaSelectOption[] = [];
                values.forEach((segment) => {
                    segmentOptions.push({
                        value: segment.toString(),
                        label: this._localizationService.instant(CropSegmentLabels[segment]),
                    });
                });
                segmentOptions.sort((a, b) => {
                    // TomatoUnavailable (label: other) should be last in the list of options
                    if (a.value === CropSegment.TomatoUnavailable.toString()) return 1;
                    if (b.value === CropSegment.TomatoUnavailable.toString()) return -1;
                    return a.label.localeCompare(b.label);
                });
                this.cropSegmentOptions = segmentOptions;
            });

        const cropSegment = !this.cropSegmentOptions.find(
            (segmentOption) => segmentOption.value === this.crop?.cropSubType.toString(),
        )
            ? null
            : this.crop.cropSubType;
        this.form?.controls.cropSegment.setValue(cropSegment);
    }

    private adjustEndDatePickerConfig(startDateString?: string) {
        // Get the just set startDate
        let startDate = this._dateService.stringToDate(startDateString);
        startDate = startDate.isValid ? startDate : dayjs();

        // Check if the current enddate is not before or on the chosen startdate
        const endDate = this._dateService.stringToDate(this.form.controls.endDate.value);
        if (endDate <= startDate) {
            // Then reset the enddate, so that the user must select a new one.
            this.form.controls.endDate.reset();
        }

        // Adjust the minimum value of the enddate datepicker, so that the user is unable to
        // select a date before the startdate.
        this.endDatePickerConfig.min = this._dateService.dateToString(startDate.add(1, 'day'));
    }

    private getFormValuesFromCrop(): formCrop {
        return {
            name: this.crop?.name,
            cropType: this.crop?.cropType,
            cropSegment: this.crop?.cropSubType,
            startDate: this.crop ? this._dateService.dateKeyToString(this.crop.startDateKey) : null,
            endDate: this.crop ? this._dateService.dateKeyToString(this.crop.endDateKey) : null,
        } as formCrop;
    }

    private getCropFromFormValues(): Crop {
        const formValues = this.form.getRawValue();
        return {
            ...this.crop,
            name: formValues.name,
            cropType: +formValues.cropType,
            cropSubType: +formValues.cropSegment,
            startDateKey: this._dateService.stringToDateKey(formValues.startDate),
            endDateKey: this._dateService.stringToDateKey(formValues.endDate),
        } as Crop;
    }
}

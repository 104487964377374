import { inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { filter, map, Observable } from 'rxjs';

import {
    PrivaVariableDataService,
    PrivaVariableRequestContainer,
    PrivaVariableResponse,
    PrivaVariableResponseData,
    ServiceType,
} from '@priva/variables';

import { roundToMetricDecimals } from '@app/metrics';

import { CROP_METRICS_REQUEST_TYPE, CropMetricsRequest } from './crop-metrics-request';
import { CropMetricsApiService } from './crop-metrics.api.service';

dayjs.extend(utc);

@Injectable({
    providedIn: 'root',
})
export class CropMetricsService implements PrivaVariableDataService {
    private readonly cropMetricsApiService = inject(CropMetricsApiService);
    public readonly source = CROP_METRICS_REQUEST_TYPE;
    public readonly type = ServiceType.Service;

    public getData(
        requestData: PrivaVariableRequestContainer<CropMetricsRequest>[],
        _offsetInMinutes: number,
        start: Date,
        end: Date,
    ): Observable<PrivaVariableResponse> {
        const metricIDs = requestData.map((requestContainer) => requestContainer.request?.metricId);
        const cropId = requestData[0].request?.cropId;
        return this.cropMetricsApiService.getCropMetrics(dayjs(start), dayjs(end), metricIDs, cropId).pipe(
            filter((response) => !!response),
            map((response) => ({
                type: this.source,
                data: response.metricValues
                    .map((metric): PrivaVariableResponseData => {
                        const requestContainer = requestData.find(
                            (container) => container.request.metricId === metric.metricId,
                        );

                        if (!requestContainer) return null;
                        return {
                            props: requestContainer,
                            values: metric.values.map((sample) => {
                                return {
                                    // We're only interested in the day here (startOf('day'), but in UTC format.
                                    x: dayjs.utc(sample.localDate).startOf('day'),
                                    y: roundToMetricDecimals(sample.value, metric.metricId),
                                };
                            }),
                        };
                    })
                    .filter((data) => !!data),
            })),
        );
    }
}

export const Colors: { [key: string]: string } = {
    blue1: '#1565c0',
    blue2: '#2bd9fe',
    blue3: '#1e9faf',
    orange1: '#eb9317',
    orange2: '#f1c40f',
    orange3: '#d64000',
    red1: '#8e0c1d',
    red2: '#f32c00',
    red3: '#ff767e',
    purple1: '#a375de',
    purple2: '#d239ff',
    purple3: '#4a306d',
    green1: '#21c332',
    green2: '#9cd300',
    green3: '#008061',
    brown1: '#534035',
    brown2: '#cf971c',
    brown3: '#8c381e',
    grey1: '#a1bbc8',
    grey2: '#436a7c',
    grey3: '#1b2529',
};

export const minMaxUnitData = {
    º: { min: 0, max: 360 },
    ºC: { min: 0, max: 25 },
    '%': { min: 0, max: 100 },
    'g/m³': { min: 0, max: 24 },
    'J/cm²': { min: 0, max: 3000 },
    Kg: { min: 0, max: 12 },
    kPa: { min: 0, max: 2.4 },
    'kg CO2/ha/h': { min: 0, max: 360 },
    kW: { min: 0, max: 120 },
    l: { min: 0, max: 5000 },
    'l/m²': { min: 0, max: 12 },
    'l/m²/24h': { min: 0, max: 24 },
    'm³/h': { min: 0, max: 12 },
    'm/s': { min: 0, max: 24 },
    'ml/MJ': { min: 0, max: 1200 },
    'mol/m²': { min: 0, max: 120 },
    'mS/cm': { min: 0, max: 12 },
    pH: { min: 0, max: 12 },
    ppm: { min: 0, max: 2400 },
    'µmol/s/m²': { min: 0, max: 2400 },
    'W/m²': { min: 0, max: 1200 },
    'ºC per 10 mol/m²': { min: 0, max: 120 },
};

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';

import { loadCropsIfNeeded } from '../crops';
import { loadGreenhousesIfNeeded } from '../greenhouses';
import { loadSitesIfNeeded } from '../sites';
import { loadExpandedCropsIfNeeded } from './expanded-crops.actions';

@Injectable({
    providedIn: 'root',
})
export class ExpandedCropsEffects {
    private readonly _actions$ = inject(Actions);

    /**
     * This effect just asks the needed resources to make sure they are loaded.
     */
    public loadExpandedCropsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadExpandedCropsIfNeeded),
            switchMap(() => {
                return of(loadCropsIfNeeded(), loadSitesIfNeeded(), loadGreenhousesIfNeeded());
            }),
        );
    });
}

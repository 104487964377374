import { inject, Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../locale/locale.service';

/*
 * Transform decimal to use correct separator
 */
@Pipe({
    name: 'numbertolocale',
    standalone: true,
})
export class NumberToLocalePipe implements PipeTransform {
    private readonly localeService = inject(LocaleService);

    public transform(value: number): string {
        return value ? value.toString().replace('.', this.localeService.localeDecimalSeparator) : '';
    }
}

@Pipe({ name: 'localetonumber', standalone: true })
export class LocaleToNumberPipe implements PipeTransform {
    public transform(value: string): number {
        // The number constructor only accepts numbers with a '.' as decimal separator
        // so replace ',' with '.' before creating a number out of it.
        return Number(value.replace(',', '.'));
    }
}

import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';

import {
    DateKeyToStringPipe,
    DateToStringPipe,
    StringToDateKeyPipe,
    StringToDatePipe,
} from './date-pipes/datekey.pipe';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    private readonly _dateKeyToStringPipe = inject(DateKeyToStringPipe);
    private readonly _stringToDateKeyPipe = inject(StringToDateKeyPipe);
    private readonly _stringToDatePipe = inject(StringToDatePipe);
    private readonly _dateToStringPipe = inject(DateToStringPipe);

    public stringToDate(dateString: string): Dayjs {
        return this._stringToDatePipe.transform(dateString);
    }

    public dateToString(date: Dayjs): string {
        return this._dateToStringPipe.transform(date);
    }

    public dateKeyToString(dateKey: number): string {
        return this._dateKeyToStringPipe.transform(dateKey);
    }

    public stringToDateKey(dateKeyString: string): number {
        return this._stringToDateKeyPipe.transform(dateKeyString);
    }
}

<priva-action-menu
    appendTo="body"
    [toggleTriggerSize]="toggleTriggerSize"
    [toggleTriggerTheme]="theme"
    [placement]="placement"
>
    <priva-action
        text="APP.CROP_ACTION_MENU.EDIT"
        icon="far fa-pen"
        id="edit-crop-details"
        (actionClick)="showEditDialog = true"
    />
    <priva-action
        text="APP.CROP_ACTION_MENU.LOCATIONS"
        icon="far fa-location-dot"
        id="edit-locations"
        (actionClick)="showLocationDialog = true"
    />
    <priva-action-divider />
    <priva-action
        status="danger"
        text="APP.CROP_ACTION_MENU.DELETE"
        icon="far fa-trash-alt"
        id="delete-crop"
        (actionClick)="showDeleteDialog = true"
    />
</priva-action-menu>

@if (showEditDialog) {
    <priva-dialog
        headerIconClass="far fa-pen"
        width="664"
        [buttons]="[]"
        [title]="'APP.CROP.DIALOG.BASIC_INFORMATION.TITLE' | translate"
        (close)="showEditDialog = false"
    >
        <app-crop-basic-information [crop]="crop" (validChange)="enableSave($event)" />
        <priva-group data-dialog-footer-end>
            <ng-container *ngTemplateOutlet="saveButtonTemplate; context: { save: onSaveCropDetails }" />
        </priva-group>
    </priva-dialog>
}

@if (showLocationDialog) {
    <priva-dialog
        headerIconClass="far fa-location-dot"
        height="800"
        width="664"
        [buttons]="[]"
        [title]="'APP.CROP.DIALOG.LOCATION.TITLE' | translate"
        (buttonClick)="onSaveLocations()"
        (close)="showLocationDialog = false"
    >
        <app-crop-location [crop]="crop" (validChange)="enableSave($event)" />
        <priva-group data-dialog-footer-end>
            <ng-container *ngTemplateOutlet="saveButtonTemplate; context: { save: onSaveLocations }" />
        </priva-group>
    </priva-dialog>
}

@if (showDeleteDialog) {
    <priva-dialog
        headerIconClass="far fa-trash-alt"
        width="664"
        [buttons]="[]"
        [subTitle]="crop.name"
        [title]="'APP.CROP.DIALOG.DELETE.TITLE' | translate"
        (close)="showDeleteDialog = false"
    >
        <app-crop-delete (deleteConfirmed)="enableDelete($event)" />

        <priva-group data-dialog-footer-end>
            <button
                id="cancel"
                priva-button
                importance="secondary"
                [textContent]="'APP.CROP.DIALOG.ACTIONS.CANCEL' | translate"
                (click)="showDeleteDialog = false"
            ></button>
            <button
                id="delete"
                data-test="confirm-delete-button"
                priva-button
                importance="primary"
                status="danger"
                [textContent]="'APP.CROP.DIALOG.ACTIONS.DELETE' | translate"
                [isDisabled]="deleteDisabled"
                (click)="onDeleteButtonsClick($event)"
            ></button>
        </priva-group>
    </priva-dialog>
}

<ng-template #saveButtonTemplate let-save="save">
    <button
        id="save"
        priva-button
        importance="primary"
        [textContent]="'APP.CROP.DIALOG.ACTIONS.SAVE' | translate"
        [isDisabled]="!saveEnabled"
        (click)="save()"
    ></button>
</ng-template>

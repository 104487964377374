import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PrivaFormGroupModule } from '@priva/components/form-group';
import { PrivaInputModule } from '@priva/components/input';
import { PrivaWellModule } from '@priva/components/well';

@Component({
    selector: 'app-crop-delete',
    templateUrl: './crop-delete.component.html',
    styleUrls: ['./crop-delete.component.scss'],
    standalone: true,
    imports: [
        PrivaWellModule,
        PrivaFormGroupModule,
        FormsModule,
        ReactiveFormsModule,
        PrivaInputModule,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropDeleteComponent implements OnInit {
    @Output() public deleteConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

    public deleteForm: FormGroup = null;

    public ngOnInit(): void {
        this.createDeleteForm();
        this.watchValidityChanges();
    }

    private createDeleteForm(): void {
        function deleteConfirmationValidator(): ValidatorFn {
            return (control: AbstractControl): ValidationErrors | null => {
                // Check if the input value matches the expected string
                const isValid = !!(control.value && control.value.toLowerCase() === 'delete');
                // If not valid, return an error object
                return !isValid ? { deleteInputError: { value: control.value } } : null;
            };
        }

        this.deleteForm = new FormGroup({
            deleteInput: new FormControl('', {
                validators: [deleteConfirmationValidator()],
                updateOn: 'change',
            }),
        });
    }

    private watchValidityChanges(): void {
        this.deleteForm.statusChanges.subscribe((status) => {
            const isValid = status === 'VALID';
            this.deleteConfirmed.emit(isValid);
        });
    }
}

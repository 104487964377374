import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { SiteStateContainer } from './sites-state.model';
import { loadSites, loadSitesIfNeeded, loadSitesSuccess, setSites } from './sites.actions';

@Injectable({
    providedIn: 'root',
})
export class SitesEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<SiteStateContainer> = inject(Store);

    /**
     * This effect checks if the sites are already in the store.
     * If not: then it will fire an action to really load the sites
     */
    public loadSitesIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadSitesIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                if (!state.site.sites) {
                    // We don't have the sites, so request it
                    return of(loadSites());
                } else {
                    // We have the sites already present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will fire an action to save this result in the store.
     */
    public loadSites$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadSitesSuccess),
            mergeMap((action) => {
                return of(setSites({ sites: action.sites }));
            }),
        );
    });
}

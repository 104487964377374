import { ExtendedCrop } from '../..';

export interface CropStateContainer {
    crop: CropsState;
}

export interface CropsState {
    crops: ExtendedCrop[];
    activeCrop: ExtendedCrop;
}

export const initialCropsState: CropsState = {
    crops: undefined,
    activeCrop: null,
};

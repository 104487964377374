import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, map, of, switchMap } from 'rxjs';

import {
    createCrop,
    createCropSuccess,
    CropLocation,
    updateCrop,
    updateCropSuccess,
    updateCropWithLocations,
} from '../..';
import { updateCropLocations } from '../row-locations';
import { createCropWithLocations } from './crops-with-locations.actions';

@Injectable({
    providedIn: 'root',
})
export class CropsWithLocationsEffects {
    private readonly _actions$ = inject(Actions);

    /**
     * This effect fires the event to create the crop and an event to 'memorize' the locations
     */
    public createCropWithLocations$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(createCropWithLocations),
            map((action) => createCrop({ crop: action.crop, context: action.cropLocations })),
        );
    });

    /**
     * This effect will fire an action to update locations for the newly created crop.
     */
    public updateCropLocationsAfterCreatedCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(createCropSuccess),
            switchMap((action) => {
                if (action.context) {
                    return of(
                        updateCropLocations({
                            cropId: action.crop.id,
                            cropLocations: action.context as CropLocation[],
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect fires the event to update the crop and an event to 'memorize' the locations
     */
    public updateCropWithLocations$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(updateCropWithLocations),
            map((action) => updateCrop({ crop: action.crop, context: action.cropLocations })),
        );
    });

    /**
     * This effect will fire an action to update locations for the updated crop.
     */
    public updateCropLocationsAfterUpdatedCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(updateCropSuccess),
            switchMap((action) => {
                if (action.context) {
                    return of(
                        updateCropLocations({
                            cropId: action.crop.id,
                            cropLocations: action.context as CropLocation[],
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        );
    });
}

import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { HttpService } from '@app/utilities';

dayjs.extend(utc);

@Injectable({
    providedIn: 'root',
})
export abstract class AnalysisApiService extends HttpService {
    private readonly _version: string = '1';

    protected get version(): string {
        return this._version;
    }
}

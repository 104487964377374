import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { MasterDataModule } from '../../services/masterdata.module';
import { CropsApiEffects } from './crops-api.effects';
import { CropStateContainer } from './crops-state.model';
import { CropsEffects } from './crops.effects';
import { cropsReducer } from './crops.reducer';

@NgModule({
    imports: [
        MasterDataModule,
        StoreModule.forFeature(nameof<CropStateContainer>('crop'), cropsReducer),
        EffectsModule.forFeature([CropsEffects, CropsApiEffects]),
    ],
    providers: [],
})
export class CropsStoreModule {}

import { Greenhouse } from '../../models';

export interface GreenhouseStateContainer {
    greenhouse: GreenhousesState;
}

export interface GreenhousesState {
    greenhouses: Greenhouse[];
}

export const initialGreenhousesState: GreenhousesState = { greenhouses: undefined };

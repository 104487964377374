import { Site } from '../../models';

export interface SiteStateContainer {
    site: SitesState;
}

export interface SitesState {
    sites: Site[];
}

export const initialSitesState: SitesState = { sites: undefined };

import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[app-numbers]',
    standalone: true,
})
export class NumbersValidatorDirective implements OnChanges {
    private allowedDecimalSeparatorsRegex: RegExp;
    private navigationKeys = [
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        'Clear',
        'Delete',
        'End',
        'Enter',
        'Escape',
        'Home',
        'Tab',
    ];
    @Input() public maxWholeDigits: number;
    @Input() public maxDecimalDigits = 2;
    @Input() public allowedDecimalSeparators = ['.', ','];
    @Input() public usedDecimalSeparator: string;

    public inputElement: HTMLInputElement;
    locale: string;

    constructor(public el: ElementRef) {
        const number = 1.1;
        this.usedDecimalSeparator = number.toLocaleString().substring(1, 2);
        this.inputElement = el.nativeElement;
        this.createAllowedDecimalsSeparatorRegex(this.allowedDecimalSeparators);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.allowedDecimalSeparators) {
            this.createAllowedDecimalsSeparatorRegex(this.allowedDecimalSeparators);
        }
    }

    /**
     * Capture and check keydown if valid input
     * Note: within the keydown event, the value of the input field doesn't contain the just typed character
     * @param {KeyboardEvent} e
     */
    @HostListener('keydown', ['$event'])
    public onKeyDown(e: KeyboardEvent) {
        const splittedNumber = this.getSplittedNumber(this.inputElement);
        if (this.checkKey(e, splittedNumber)) {
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        // NOTE: a space will result in number '0', so therefore an extra check is needed for space.
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault();
            return;
        }

        if (splittedNumber.length === 1) {
            // No decimal separator is used: 0 00 000
            this.checkFullNumber(e, splittedNumber);
        } else {
            // A decimal separator is used: 0,0 0:00 00.0
            this.checkDecimalNumber(e, splittedNumber);
        }
    }

    /**
     * Capture and check keyup and format complete string
     * Note: within the keyup event, the value of the input field already contains the just typed character
     */
    @HostListener('keyup', ['$event'])
    public onKeyUp() {
        const splittedNumber = this.inputElement.value.split(this.allowedDecimalSeparatorsRegex);
        this.formatInputValue(splittedNumber);
    }

    /**
     * Capture and check keyup and format complete string
     * Note: within the keyup event, the value of the input field already contains the just typed character
     */
    @HostListener('paste', ['$event'])
    public onPaste(e: ClipboardEvent) {
        const textToPaste = e.clipboardData.getData('text');
        const textAsNumber = Number(textToPaste.replace(',', '.'));
        if (isNaN(textAsNumber)) {
            e.preventDefault();
            return;
        }

        // Because we want to limit the amount of decimals, we adjust the value here...
        const splittedPastedNumber = textToPaste.split(this.allowedDecimalSeparatorsRegex);
        if (splittedPastedNumber.length > 1) {
            splittedPastedNumber[1] = splittedPastedNumber[1].slice(0, this.maxDecimalDigits);
        }

        this.inputElement.selectionStart = 0;
        this.inputElement.selectionEnd = this.inputElement.selectionStart;
        this.formatInputValue(splittedPastedNumber);

        // ... and thus we have to cancel the event, because we did it ourselves.
        e.preventDefault();
    }

    /**
     * This method will check if the typed key is allowed.
     * @param e The event containing the just typed key
     * @param splittedNumber The current input value without the just typed character splitted on the decimal separator
     * @returns true: the key is allowed otherwise false
     */
    private checkKey(e: KeyboardEvent, splittedNumber: string[]): boolean {
        const isAllowedSeparator = this.checkSeparatorKey(e, splittedNumber);
        return (
            this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys.
            this.checkCharacter(e, 'a', 'A') || // Allow: Ctrl+A or Cmd+A (Mac)
            this.checkCharacter(e, 'c', 'C') || // Allow: Ctrl+C or Cmd+C (Mac)
            this.checkCharacter(e, 'x', 'X') || // Allow: Ctrl+X or Cmd+X (Mac)
            this.checkCharacter(e, 'v', 'V') || // Allow: Ctrl+V or Cmd+V (Mac)
            isAllowedSeparator
        );
    }

    private checkSeparatorKey(e: KeyboardEvent, splittedNumber: string[]): boolean {
        // Check if the given key is an allowed separator
        const isAllowedSeparatorKey = this.allowedDecimalSeparators.some((ds) => e.key === ds);

        // Check if the value doesn't already contains a separator
        const isSeparatorNotPresent = splittedNumber.length === (this.maxDecimalDigits === 0 ? 0 : 1);

        // Check if a separator is allowed at the current cursor position (respecting maxDecimalDigits)
        const cursorPosition = this.inputElement.selectionStart;
        const isSeparatorAllowedAtPosition =
            this.inputElement.value.length - cursorPosition <= this.maxDecimalDigits;

        return isAllowedSeparatorKey && isSeparatorNotPresent && isSeparatorAllowedAtPosition;
    }

    private checkCharacter(e: KeyboardEvent, character1: string, character2: string): boolean {
        return (e.key === character1 || e.key === character2) && (e.ctrlKey === true || e.metaKey === true);
    }

    /**
     * This method checks if the number entered is not longer than specified by the input parameter 'maxWholeDigits'.
     * @param e Event containing the just typed key
     * @param splittedNumber  The current input value without the just typed character splitted on the decimal separator
     */
    private checkFullNumber(e: KeyboardEvent, splittedNumber: string[]) {
        if (this.maxWholeDigits) {
            if (splittedNumber[0].length >= this.maxWholeDigits) {
                e.preventDefault();
            }
        }
    }

    /**
     * This method checks if the number entered is correct in length for digits before and after the decimal separator
     * @param e Event containing the just typed key
     * @param splittedNumber The current input value without the just typed character splitted on the decimal separator
     */
    private checkDecimalNumber(e: KeyboardEvent, splittedNumber: string[]) {
        const cursorPosition = this.inputElement.selectionStart;
        const separatorPosition = this.inputElement.value.search(this.allowedDecimalSeparatorsRegex);

        if (cursorPosition <= separatorPosition) {
            // We're isEditing the integer part
            this.checkFullNumber(e, splittedNumber);
        } else {
            // We're isEditing the fractional part
            if (splittedNumber[1].length >= this.maxDecimalDigits) {
                e.preventDefault();
            }
        }
    }

    private formatInputValue(splittedNumber: string[]) {
        // A user can enter all decimals specified by the 'allowedDecimalSeparators' input parameter
        // The aim here is to format the input using the 'usedDecimalSeparator'
        const selectionStart = this.inputElement.selectionStart;
        const selectionEnd = this.inputElement.selectionEnd;
        this.inputElement.value = splittedNumber.join(this.usedDecimalSeparator);
        this.inputElement.selectionStart = selectionStart;
        this.inputElement.selectionEnd = selectionEnd;

        // To actually update the input element, we need to fire this event after changing its value
        this.inputElement.dispatchEvent(new Event('input'));
    }

    private getSplittedNumber(nativeElement: any): string[] {
        const selectedText = nativeElement.value.substring(
            nativeElement.selectionStart,
            nativeElement.selectionEnd,
        );

        const remainingText = nativeElement.value.replace(selectedText, '');
        return remainingText.split(this.allowedDecimalSeparatorsRegex);
    }

    private createAllowedDecimalsSeparatorRegex(allowedDecimalSeparators: string[]) {
        this.allowedDecimalSeparatorsRegex = new RegExp('[' + allowedDecimalSeparators.join('') + ']', 'g');
    }
}

import { RowLocation } from '../../models';

export interface RowLocationStateContainer {
    rowLocation: RowLocationsState;
}

export interface RowLocationsState {
    rowLocations: RowLocation[];
}

export const initialRowLocationsState: RowLocationsState = { rowLocations: undefined };

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { SessionStorage } from '@app/utilities';

import { AnalysisStateContainer } from './analysis-state.model';
import {
    loadMetricDefinitions,
    loadMetricDefinitionsIfNeeded,
    loadMetricDefinitionsSuccess,
    setMetricDefinitions,
} from './analysis.actions';

@Injectable({
    providedIn: 'root',
})
export class AnalysisEffects extends SessionStorage {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<AnalysisStateContainer> = inject(Store);

    protected readonly SESSION_STORAGE_KEY = 'cropAnalysis';

    constructor() {
        super();
    }

    /**
     * This effect checks if the metric definitions are already in the store.
     * If not: then it will fire an action to really load the metric definitions.
     */
    public loadMetricDefinitionsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadMetricDefinitionsIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                const storedData = this.getSessionStorage();
                if (!state.analysis.metricDefinitions) {
                    // We don't have the metric definitions, so request it
                    return of(
                        storedData
                            ? setMetricDefinitions({ metricDefinitions: storedData.metricDefinitions })
                            : loadMetricDefinitions(),
                    );
                } else {
                    // We have the metricDefinitions present, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will convert the received metricList into a simple array of strings
     * and will fire an action to save this result in the store.
     */
    public loadMetricDefinitionsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadMetricDefinitionsSuccess),
            mergeMap((action) => {
                const storedData = this.getSessionStorage();
                this.setSessionStorage({ ...storedData, metricDefinitions: action.metricDefinitions });

                return of(setMetricDefinitions({ metricDefinitions: action.metricDefinitions }));
            }),
        );
    });
}

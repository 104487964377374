import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { MasterDataModule } from '../../services/masterdata.module';
import { RowLocationsApiEffects } from './row-locations-api.effects';
import { RowLocationStateContainer } from './row-locations-state.model';
import { RowLocationsEffects } from './row-locations.effects';
import { rowLocationsReducer } from './row-locations.reducer';

@NgModule({
    imports: [
        MasterDataModule,
        StoreModule.forFeature(nameof<RowLocationStateContainer>('rowLocation'), rowLocationsReducer),
        EffectsModule.forFeature([RowLocationsEffects, RowLocationsApiEffects]),
    ],
    providers: [],
})
export class RowLocationsStoreModule {}

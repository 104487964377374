import { createSelector } from '@ngrx/store';

import { GreenhousesState, GreenhouseStateContainer } from './greenhouses-state.model';

export const selectGreenhouseState = (state: GreenhouseStateContainer) => state.greenhouse;

export const selectGreenhouses = createSelector(
    selectGreenhouseState,
    (state: GreenhousesState) => state.greenhouses,
);

import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Placement as PopperPlacement } from '@popperjs/core';

import { PrivaActionMenuModule } from '@priva/components/action-menu';
import { PrivaActionThemeType } from '@priva/components/action-menu/lib/action-menu.model';
import { PrivaButtonModule, PrivaButtonSizeType } from '@priva/components/button';
import { PrivaDialogSimpleModule } from '@priva/components/dialog-simple';
import { PrivaGroupModule } from '@priva/components/group';
import { PrivaLocalizationModule } from '@priva/localization';
import { Crop, CropLocation, deleteCrop, updateCrop, updateCropWithLocations } from '@priva/masterdata';

import { CropBasicInformationComponent } from '../crop-basic-information/crop-basic-information.component';
import { CropDeleteComponent } from '../crop-delete/crop-delete.component';
import { CropLocationComponent } from '../crop-location/crop-location.component';

@Component({
    selector: 'app-crop-action-menu',
    templateUrl: './crop-action-menu.component.html',
    standalone: true,
    imports: [
        PrivaActionMenuModule,
        PrivaDialogSimpleModule,
        CropBasicInformationComponent,
        PrivaGroupModule,
        NgTemplateOutlet,
        CropLocationComponent,
        CropDeleteComponent,
        PrivaButtonModule,
        PrivaLocalizationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropActionMenuComponent {
    private readonly _store = inject(Store);

    @Input() public crop: Crop;
    @Input() public theme: PrivaActionThemeType = 'onwhite';
    @Input() public toggleTriggerSize: PrivaButtonSizeType = 'sm';
    @Input() public placement: PopperPlacement = 'bottom-end';

    public showEditDialog: boolean;
    public showDeleteDialog: boolean;
    public showLocationDialog: boolean;
    public saveEnabled = false;
    public deleteDisabled = true;

    public locations: CropLocation[] = undefined;

    public onSaveCropDetails = () => {
        this.showEditDialog = false;
        this._store.dispatch(updateCrop({ crop: this.crop }));
    };

    public onSaveLocations = () => {
        this.showLocationDialog = false;
        this._store.dispatch(updateCropWithLocations({ crop: this.crop, cropLocations: this.locations }));
    };

    public enableSave(validation: { isValid: boolean; crop: Crop; locations?: CropLocation[] }) {
        if (validation.isValid) {
            this.crop = validation.crop;
            this.locations = validation.locations ?? this.locations;
        }
        this.saveEnabled = validation.isValid;
    }

    public enableDelete(isValid: boolean) {
        this.deleteDisabled = !isValid;
    }

    public onDeleteButtonsClick(event) {
        this.showDeleteDialog = false;
        if (event.target.id !== 'delete' || !this.crop.id) return;
        this._store.dispatch(deleteCrop({ cropId: this.crop.id }));
    }
}

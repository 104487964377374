import { createAction, props } from '@ngrx/store';

import { Greenhouse } from '../../models';

export const loadGreenhousesIfNeeded = createAction('[Greenhouses] Load greenhouses if needed');

export const loadGreenhouses = createAction('[Greenhouses] Load greenhouses');

export const loadGreenhousesSuccess = createAction(
    '[Greenhouses] Load greenhouses success',
    props<{ greenhouses: Greenhouse[] }>(),
);

export const setGreenhouses = createAction(
    '[Greenhouses] Set greenhouses',
    props<{ greenhouses: Greenhouse[] }>(),
);

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    private readonly _localeDecimalSeparator: string;

    constructor() {
        const number = 1.1;
        this._localeDecimalSeparator = number.toLocaleString().substring(1, 2);
    }

    public get localeDecimalSeparator(): string {
        return this._localeDecimalSeparator;
    }
}

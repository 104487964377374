import { inject, Injectable } from '@angular/core';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { take } from 'rxjs';

import { PrivaLocalizationService } from '@priva/localization';

import {
    DateFormat,
    DayFormat,
    MonthFormat,
    MonthYearFormat,
    WeekDayFormat,
    YearFormat,
} from './date-constants';

@Injectable({
    providedIn: 'root',
})
export class DatePickerService {
    private readonly _localizationService = inject(PrivaLocalizationService);
    private readonly _defaultConfig: IDatePickerConfig = {
        format: DateFormat.toUpperCase(),
        firstDayOfWeek: 'mo',
        dayBtnFormat: DayFormat,
        weekDayFormat: WeekDayFormat,
        monthFormat: MonthYearFormat,
        monthBtnFormat: MonthFormat,
        yearFormat: YearFormat,
        showWeekNumbers: true,
        closeOnSelect: true,
        showGoToCurrent: false,
        unSelectOnClick: false,
        enableMonthSelector: true,
        disableKeypress: true,
        returnedValueType: ECalendarValue.String,
    };

    private _locale: string;

    constructor() {
        this._localizationService.language.pipe(take(1)).subscribe((language: string) => {
            this._locale = language.substring(0, 2);
        });
    }

    public createConfig(options?: IDatePickerConfig): IDatePickerConfig {
        return {
            ...this._defaultConfig,
            ...options,
            locale: this._locale,
        } as IDatePickerConfig;
    }
}

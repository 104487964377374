import { createReducer, on } from '@ngrx/store';

import { initialMonitoringState, MonitoringState } from './monitoring-state.model';
import { setKpiDefinitions } from './monitoring.actions';

export const monitoringReducer = createReducer(
    initialMonitoringState,
    on(setKpiDefinitions, (state, action): MonitoringState => {
        return {
            ...state,
            kpiDefinitions: action.kpiDefinitions,
        };
    }),
);

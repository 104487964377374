import { createAction, props } from '@ngrx/store';

import { Crop, CropLocation } from '../..';

export const createCropWithLocations = createAction(
    '[Crops with locations] create crop with locations',
    props<{ crop: Crop; cropLocations: CropLocation[] }>(),
);

export const updateCropWithLocations = createAction(
    '[Crops with locations] update crop with locations',
    props<{ crop: Crop; cropLocations: CropLocation[] }>(),
);

import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';

import { AppConfigurationService, dateToLocalDateString } from '@app/utilities';

import { AnalysisApiService } from './analysis.api.service';
import { CropMetrics } from './crop-metrics.model';

@Injectable({
    providedIn: 'root',
})
export class CropMetricsApiService extends AnalysisApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.analysisService;
    }

    public getCropMetrics(
        start: Dayjs,
        end: Dayjs,
        metricIds: string[],
        cropId: string,
    ): Observable<CropMetrics> {
        let params = new HttpParams();
        metricIds.forEach((metricId) => {
            params = params.append('metricIds', metricId);
        });
        params = params.append('localStartDate', dateToLocalDateString(start));
        params = params.append('localEndDate', dateToLocalDateString(end));

        return this.get<CropMetrics>(`crops/${cropId}/metrics/aggregates/dates`, [], params);
    }
}

import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { Crop, CropLocation, RowLocation } from '../models';
import { MasterdataApiService } from './masterdata-api.service';

@Injectable({
    providedIn: 'root',
})
export class CropsApiService extends MasterdataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    protected get baseUri(): string {
        return this._appConfigurationService.configuration.uris.cropsService;
    }

    public getCrops(): Observable<Crop[]> {
        return this.get<Crop[]>();
    }

    public createCrop(crop: Crop): Observable<Crop> {
        // Masterdata contains a bug, variety is not optional, so always provide empty string when undefined
        return this.post<Crop>('', { ...crop, variety: crop.variety ?? '' });
    }

    public updateCrop(crop: Crop): Observable<Crop> {
        // Masterdata contains a bug, variety is not optional, so always provide empty string when undefined
        return this.put<Crop>(`${crop.id}`, { ...crop, variety: crop.variety ?? '' });
    }

    public deleteCrop(cropId: string): Observable<Crop> {
        // deleting a crop through the masterdata api will automatically deleted Linked locations.
        return this.delete(cropId);
    }

    public getLocations(cropId: string): Observable<RowLocation[]> {
        return this.get<RowLocation[]>(`${cropId}/Locations`);
    }

    public updateLocations(
        cropId: string,
        cropLocations: CropLocation[],
    ): Observable<Record<string, boolean>> {
        return this.put<CropLocation[], Record<string, boolean>>(`${cropId}/Locations`, cropLocations);
    }
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { Site } from '../../models';
import { SitesApiService } from '../../services';
import { loadSites, loadSitesSuccess } from './sites.actions';

@Injectable({
    providedIn: 'root',
})
export class SitesApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _sitesService = inject(SitesApiService);

    /**
     * This effect will really request the sites from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadSites$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadSites),
            mergeMap(() => {
                return this._sitesService.getSites().pipe(
                    map((sites: Site[]) => loadSitesSuccess({ sites: sites })),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { MasterDataModule } from '../../services/masterdata.module';
import { SitesApiEffects } from './sites-api.effects';
import { SiteStateContainer } from './sites-state.model';
import { SitesEffects } from './sites.effects';
import { sitesReducer } from './sites.reducer';

@NgModule({
    imports: [
        MasterDataModule,
        StoreModule.forFeature(nameof<SiteStateContainer>('site'), sitesReducer),
        EffectsModule.forFeature([SitesEffects, SitesApiEffects]),
    ],
    providers: [],
})
export class SitesStoreModule {}
